import {
  Box, Paper, Typography, Button, useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CompanyNewsDto from '../../models/dtos/comapnyNewsDto';
import './SingleNews.css';

interface SingleNewsProps{
  newsData: CompanyNewsDto,
}

export default function SingleNews({
  newsData,
}: SingleNewsProps) {
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false);
  const [newsShadowColor, setNewsShadowColor] = useState<string>();
  const Theme = useTheme();

  useEffect(() => {
    if (Theme.palette.mode === 'light') {
      setNewsShadowColor('rgba(255,255,255');
    } else {
      setNewsShadowColor('rgba(077,077,077');
    }
  }, [Theme]);

  function handleMouseEnter() {
    setDescriptionIsOpen(() => true);
  }

  function handleMouseLeave() {
    setDescriptionIsOpen(() => false);
  }

  function handleClickMore() {
    window.open(newsData.url);
  }

  return (
    <Paper
      sx={{ minWidth: '250px', minHeight: '300px', position: 'relative' }}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      elevation={7}
    >
      <Box sx={{ height: 250, position: 'relative' }}>
        <img
          src={newsData.image}
          alt={newsData.image}
          className="single-news-image"
          draggable={false}
        />
      </Box>
      <Box sx={{
        width: '100%',
        position: 'absolute',
        bottom: 0,
      }}
      >
        <Box sx={{
          maxHeight: 120,
          background: `linear-gradient(${newsShadowColor},0), ${newsShadowColor},0.7), ${newsShadowColor},0.8), ${newsShadowColor},0.9), ${newsShadowColor},0.95), ${newsShadowColor},1), ${newsShadowColor},1))`,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'pre-line',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          padding: '20px 20px 0 20px',
        }}
        >
          <Typography
            sx={{
              textOverflow: 'ellipsis',
            }}
            variant="h6"
          >
            {newsData.title}
          </Typography>
        </Box>
        <Box
          sx={{
            background: `${newsShadowColor})`,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0 10px 0 10px',
          }}
        >
          <Box sx={{
            height: 40,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'start',
            flexDirection: 'column',
          }}
          >
            <Typography variant="body1">
              {newsData.site}
            </Typography>
            <Typography
              variant="overline"
              sx={{
                color: '#4d4d4d',
                margin: '-10px 0 0 0',
              }}
            >
              {newsData.publishedDate}
            </Typography>
          </Box>
          <Button
            sx={{ borderRadius: 20 }}
            endIcon={<OpenInNewIcon />}
            onClick={() => handleClickMore()}
            size="small"
          >
            More
          </Button>
        </Box>
        <Box sx={{
          height: descriptionIsOpen ? 150 : 0,
          background: `${newsShadowColor})`,
          transition: 'height 1.5s',
        }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              overflowX: 'hidden',
              overflowY: 'auto',
              textAlign: 'center',
              whiteSpace: 'normal',
              height: 150,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                padding: '10px 40px 10px 40px',
              }}
            >
              {newsData.text}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
