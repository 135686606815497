import { Box, Divider, Typography } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Markdown from './components/markdown';
import useBlogPost from '../../hooks/useBlogPost';
import Loader from '../../components/Loader';

export default function BlogPost() {
  const { id } = useParams();
  const { data: blogPost, isLoading } = useBlogPost(id);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Helmet>
        <title>
          {blogPost?.title ?? '' as string}
          {' '}
          | StockTradeIQ
        </title>
      </Helmet>
      <Box
        className="center-items"
        sx={{
          flexGrow: '1', margin: {xs: '15%', lg: '25%'}, marginTop: '5%', marginBottom: '5%', maxWidth: '850px',
        }}
      >
        {(isLoading)
          ? <Loader />
          : (
            <Box>
              <Markdown className="markdown" key={blogPost?.id}>
                {blogPost?.markdownBody}
              </Markdown>
              <Typography
                sx={{
                  height: '80%', marginTop: '40px', textAlign: 'center', fontSize: '12px', fontWeight: '400', textTransform: 'none',
                }}
                className="center-items"
                variant="h3"
              >
                <b>Disclaimer:</b> This article is for educational purposes only and not intended as financial advice. Do your own research before making investment decisions.
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
}
