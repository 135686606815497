import { keyframes } from "@emotion/react";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import DoubleArrowsIcon from '../../../assets/doubleArrows.png';
import ProfileImage from '../../../assets/dashboard/profile.png';
import KeyTakeawaysImage from '../../../assets/dashboard/keyTakeaways.png';
import ScreenerImage from '../../../assets/dashboard/screener.png';
import StatisticsImage from '../../../assets/dashboard/statistics.png';
import { useSmoothScroll } from "../../../hooks/useSmoothScroll";

export default function FunctionsWithCarouselMobile() {
    const { smoothScrollDown } = useSmoothScroll();

    const cloudMove = keyframes`
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(-10%, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    `;
    const { instance } = useMsal();


    return (
        <Box
            sx={{
                width: '100%',
                overflow: 'hidden',
                background: 'white',
                alignItems: 'center',
                textAlign: 'center',
                paddingTop: '10%'
            }}
        >
            <Box sx={{
                margin: '3%',
                background: '#DBE6FA',
                justifyContent: 'center',
                padding: '7%',
                borderRadius: '20px'
            }}>
                <Typography sx={{
                    fontSize: '23px',
                    fontWeight: '600'
                }}>
                    Make intelligent investment decisions with us
                </Typography>
            </Box>
            <Box sx={{ padding: '5% 15% 5% 15%' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '400' }}>
                    We are the only investment tool you need to make <span style={{ fontWeight: '600' }}>informed</span> decisions
                </Typography>
            </Box>
            <Box sx={{ paddingTop: '50px' }}>
                <Swiper
                    style={{
                        width: '100%',
                    }}
                    modules={[EffectCoverflow]}
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={1.4}
                    spaceBetween={15}
                    coverflowEffect={{
                        rotate: 20,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    pagination={{ clickable: true }}
                    navigation={true}
                    loop={true}
                >
                    <SwiperSlide>
                        <Box
                            sx={{
                                width: '100%',
                                margin: '0 auto',
                            }}
                        >
                            <img
                                src={ProfileImage}
                                style={{
                                    width: '100%',
                                    aspectRatio: '9/8', // Slightly adjusted ratio for better proportions
                                    objectFit: 'cover',
                                    borderRadius: '10px'
                                }}
                            />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box
                            sx={{
                                width: '100%',
                                height: 'auto',
                                margin: '0 auto',
                            }}
                        >
                            <img
                                src={KeyTakeawaysImage}
                                style={{
                                    width: '100%',
                                    aspectRatio: '9/8',
                                    objectFit: 'cover',
                                    borderRadius: '10px'
                                }}
                            />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box
                            sx={{
                                width: '100%',
                                height: 'auto',
                                margin: '0 auto',
                            }}
                        >
                            <img
                                src={ScreenerImage}
                                style={{
                                    width: '100%',
                                    aspectRatio: '9/8',
                                    objectFit: 'cover',
                                    borderRadius: '10px'
                                }}
                            />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box
                            sx={{
                                width: '100%',
                                height: 'auto',
                                margin: '0 auto',
                            }}
                        >
                            <img
                                src={StatisticsImage}
                                style={{
                                    width: '100%',
                                    aspectRatio: '9/8',
                                    objectFit: 'cover',
                                    borderRadius: '10px'
                                }}
                            />
                        </Box>
                    </SwiperSlide>
                </Swiper>
            </Box>
            <Box sx={{
                margin: '5% 3% 5% 3%',
                justifyContent: 'center',
                padding: '7%',
            }}>
                <Typography sx={{
                    fontSize: '25px',
                    fontWeight: '600'
                }}>
                    Searching for investment information has never been so effective:
                </Typography>
            </Box>
            <Box sx={{ padding: '0 5% 0 5%' }}>
                <Box sx={{ marginBottom: '10%' }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box>
                            <div style={{ transform: 'rotate(270deg)' }}>
                                <img src={DoubleArrowsIcon} style={{ width: '15px' }} />
                            </div>
                        </Box>
                        <Typography sx={{
                            fontSize: '20px',
                            fontFamily: 'Calibre, sans-serif',
                            fontWeight: '600',
                        }}>Learn more and quicker</Typography>
                    </Stack>
                    <Typography sx={{
                        fontSize: '16px',
                        lineHeight: '18px',
                        fontFamily: 'Calibre, sans-serif',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }}>
                        Use a comprehensive stock screener tools suite with a <span style={{ fontWeight: 600 }}>scoring mechanism</span> and <span style={{ fontWeight: 600 }}>key takeaways</span> to elevate your stock screening experience to a new level.
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: '10%' }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box>
                            <div style={{ transform: 'rotate(270deg)' }}>
                                <img src={DoubleArrowsIcon} style={{ width: '15px' }} />
                            </div>
                        </Box>
                        <Typography sx={{
                            fontSize: '20px',
                            fontFamily: 'Calibre, sans-serif',
                            fontWeight: '600',
                        }}>Stay informed</Typography>
                    </Stack>
                    <Typography sx={{
                        fontSize: '16px',
                        lineHeight: '18px',
                        fontFamily: 'Calibre, sans-serif',
                        fontWeight: '400',
                        paddingLeft: '30px',
                        textAlign: 'left'
                    }}>
                        Track the market situation for your stock or investment opportunities with dedicated price and <span style={{ fontWeight: 600 }}>fundamental alerts</span>, multiple <span style={{ fontWeight: 600 }}>watchlists</span>, and <span style={{ fontWeight: 600 }}>portfolios</span> you can manage within the app.
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: '5%' }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box>
                            <div style={{ transform: 'rotate(270deg)' }}>
                                <img src={DoubleArrowsIcon} style={{ width: '15px' }} />
                            </div>
                        </Box>
                        <Typography sx={{
                            fontSize: '20px',
                            fontFamily: 'Calibre, sans-serif',
                            fontWeight: '600',
                        }}>Make informed decisions</Typography>
                    </Stack>
                    <Typography sx={{
                        fontSize: '16px',
                        lineHeight: '18px',
                        fontFamily: 'Calibre, sans-serif',
                        fontWeight: '400',
                        paddingLeft: '30px',
                        textAlign: 'left'
                    }}>
                        Let us help you utilize full history of available market data for <span style={{ fontWeight: 600 }}>34000</span> stocks from <span style={{ fontWeight: 600 }}>90</span> stock exchanges worldwide to choose the best market and the best stock {`to\u00A0invest\u00A0in\u00A01`}
                    </Typography>
                </Box>
            </Box>
            <Paper elevation={4} sx={{ width: '80%', height: '52px', marginTop: '20%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Button
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    aria-label="Try it for free"
                    sx={{
                        width: '100%',
                        height: '100%',
                        fontWeight: '400',
                        fontSize: '17px',
                        backgroundColor: '#30CFD0',
                        textTransform: 'none'
                    }}
                    onClick={() => instance.loginRedirect(loginRequest)}
                >
                    Try for Free
                </Button>
            </Paper>
        </Box>
    );
}