import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import useKeyTakeawaysStyles from '../useKeyTakeawaysStyles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate } from 'react-router-dom';

interface STIScorePropsModel {
  score: number,
}

export default function STIScore(props: STIScorePropsModel) {
  const classes = useKeyTakeawaysStyles();
  const { score } = props;
  const navigate = useNavigate();

  const handleScoreClick = () => {
    // navigate('/topSTI');
  };

  function stiScoreColor() {

    if (score < 8) {
      return '#E53935';
    } else if (score >= 8 && score <= 11) {
      return '#EF6C00';
    } else if (score > 11) {
      return '#4CAF50';
    }
  }

  return (
    <Stack sx={{ width: '100%', height: '100%' }} direction={"row"}>
      <Box
        sx={{
          backgroundColor: stiScoreColor() ?? '#4CAF50',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '90%',
          paddingLeft: '30%',
          paddingRight: '30%',
          boxShadow: 3,
          cursor: 'pointer',
          // transition: 'transform 0.2s, box-shadow 0.2s',
          // '&:hover': {
          //   transform: 'scale(1.02)',
          //   boxShadow: 4
          // },
          // '&:active': {
          //   transform: 'scale(0.98)'
          // }
        }}
        onClick={handleScoreClick}
        role="button"
        aria-label="View top STI scores"
      >
        <Stack direction={"row"} alignContent={'center'}>
          <Typography variant="h6" color="white" align='center'>
            {"STI:"}
          </Typography>
          <Typography variant="h6" color="white" style={{ marginLeft: '5px' }}>
            {score}
          </Typography>
        </Stack>
      </Box>
      <Box position={'relative'}>
        <Tooltip style={{ position: 'absolute', top: '-25px', left: '-10px'}} title="STI score = 10 - 2 * [number of adverse key takeaways] + [number of favorable key takeaways]">
          <IconButton size="small" >
            <HelpOutlineIcon style={{ fontSize: "1rem" }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
}