import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import AnalyticsGrid from '../../../components/analyticsGrid/analyticsGrid';
import ShareGridStateDto from '../../../models/dtos/shareGridStateDto';
import { useEffect, useState, useRef } from 'react';
import Joyride, { Step, ACTIONS, CallBackProps, STATUS } from 'react-joyride';

interface DividendStockScreenerProps {
  sharedScreenConfiguration?: ShareGridStateDto | null;
}

export default function DividendStockScreener({
  sharedScreenConfiguration = undefined,
}: DividendStockScreenerProps) {
  const [runTour, setRunTour] = useState(false);
  // Add this to ensure tour doesn't run on every render
  const [tourComplete, setTourComplete] = useState(
    localStorage.getItem('stockAnalysisIntroSeen') === 'true'
  );
  
  // Reference to track if this is the initial render
  const isInitialRender = useRef(true);

  // Run the tour automatically when component mounts
  useEffect(() => {
    // Only run the tour if it hasn't been completed before
    if (!tourComplete && isInitialRender.current) {
      isInitialRender.current = false;
      // Longer delay to ensure components are fully rendered and visible
      const timer = setTimeout(() => {
        setRunTour(true);
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [tourComplete]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;
    
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      // Update localStorage and state when tour is finished
      setRunTour(false);
      setTourComplete(true);
      localStorage.setItem('stockAnalysisIntroSeen', 'true');
    } else if (action === ACTIONS.CLOSE) {
      setRunTour(false);
    }
  };

  const steps: Step[] = [
    {
      target: '.analyzed-view-switch',
      content: (
        <div>
          <h3>Analyzed View</h3>
          <p>This feature is especially helpful for less experienced investors. When enabled, it displays simplified interpretations of complex financial metrics, making stock analysis more accessible.</p>
          <p>Try switching it on to see more intuitive data displays!</p>
        </div>
      ),
      placement: 'bottom',
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '.sti-score-column-header',
      content: (
        <div>
          <h3>STI Score</h3>
          <p>Our unique proprietary indicator designed specifically for value investors. The STI Score combines multiple financial metrics into one easy-to-understand rating.</p>
          <p>Higher scores indicate stocks that better align with value investing principles!</p>
        </div>
      ),
      placement: 'right',
      spotlightClicks: true,
      spotlightPadding: 5,
      offset: 0
    }
  ];

  return (
    <Box sx={{ minHeight: '100%', width: '100%', marginTop: { xs: '70px', lg: '0px' } }}>
      <Helmet>
        <title>Stock Screener | Find Top Dividend Stocks | StockTradeIQ</title>
      </Helmet>
      
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        disableScrolling={false}
        disableOverlayClose={true}
        spotlightClicks={false}
        floaterProps={{
          disableAnimation: true,
          styles: {
            arrow: {
              length: 10,
              spread: 8,
            },
          },
        }}
        styles={{
          options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            primaryColor: '#2196F3',
            textColor: '#333',
            zIndex: 1500,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
          },
          tooltip: {
            fontSize: '14px',
            padding: '15px',
            borderRadius: '8px',
          },
          buttonNext: {
            backgroundColor: '#2196F3',
            borderRadius: '4px',
            color: '#fff',
          },
          buttonBack: {
            marginRight: 10,
            color: '#666',
          },
          buttonSkip: {
            color: '#999',
          },
          spotlight: {
            backgroundColor: 'transparent',
          },
        }}
        callback={handleJoyrideCallback}
        locale={{
          last: 'Finish',
          skip: 'Skip tour'
        }}
      />
      
      <AnalyticsGrid
        customColumnVisibilityModel={{
          grahamNetNet: false,
          roe: false,
          roic: false,
          quickRatioTTM: false,
          currentRatioTTM: false,
          pegRatioTTM: false,
          priceCashFlowRatioTTM: false,
          priceToFreeCashFlowsRatioTTM: false,
          grossProfitMarginTTM: false,
          netProfitMarginTTM: false,
          returnOnAssetsTTM: false,
        }}
        showGoalTextField
        hiddenColumns={[]}
        screenerName="dividend"
        showAll
        showFavourite
        showIgnored={false}
        showOnlyDividendStocksButton
        sharedScreenConfiguration={sharedScreenConfiguration}
      />
    </Box>
  );
}