import {
    Box,
    Typography,
    Paper,
    Grid,
    Button
} from '@mui/material';
import useGlobalStyles from '../../../../../../styles/useGlobalStyles';
import Loader from '../../../../../../components/Loader';
import AIBackground from '../../../../../../assets/detailedData/AI-background.png'
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useSnackbar } from 'notistack';
import { loginRequest } from '../../../../../../authConfig';
import { useFreeResourcesExceededDialogContext } from '../../../../../../contexts/FreeResourcesExceededDialogContext';
import { FunctionalityType } from '../../../../../../models/subscriptionConfig';
import SubscriptionService from '../../../../../../services/subscriptionService';

interface AskAIPropsType {
    stockSymbol: string | undefined,
    paperHeight: string
}

const AskAI = ({ stockSymbol, paperHeight }: AskAIPropsType) => {
    const globalClasses = useGlobalStyles();
    const isLoading = false;
    const { enqueueSnackbar } = useSnackbar();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();

    const handleButtonClick = async () => {

        if (!isAuthenticated) {
            // Redirect unauthenticated users to login
            instance.loginRedirect(loginRequest);
            return;
        }
        await SubscriptionService.CanSeeFunctionality(FunctionalityType.Ai);
        enqueueSnackbar(
            'You are registered for early bird access to our AI features. Our team will contact you shortly.',
            {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                autoHideDuration: 5000
            }
        );
    };


    return (
        <Paper sx={{
            width: '100%', height: paperHeight, textAlign: 'center', display: 'flex', flexDirection: 'column',
        }} elevation={4}>
            <Typography
                sx={{
                    padding: '15px 50px 5px 15px;', width: '100%', display: 'flex', justifyContent: 'center', fontSize: '16px', fontWeight: 700
                }}
                variant="h6"
                className={globalClasses.header}
            >
                ASK OUR AI ABOUT THE COMPANY <span style={{ fontWeight: '1000', marginLeft: '40px' }}>(REGISTER FOR EARLY ACCESS)</span>
            </Typography>
            {isLoading
                ? (
                    <Box sx={{ width: '100%', minHeight: '150px', flexGrow: 1 }} className="center-items">
                        <Loader />
                    </Box>
                )
                : (
                    <Grid container sx={{ paddingTop: '20px', px: '20px' }} spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Button variant='outlined' sx={{ background: '#2196F3', color: 'white', width: '100%' }} onClick={handleButtonClick}>
                                COMPANY OVERVIEW
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button variant='outlined' sx={{ background: '#2196F3', color: 'white', width: '100%' }} onClick={handleButtonClick}>
                                FUNDAMENTAL ANALYSIS
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button variant='outlined' sx={{ background: '#2196F3', color: 'white', width: '100%' }} onClick={handleButtonClick}>
                                GROWTH POTENTIAL
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ position: 'relative' }}>
                                <img
                                    src={AIBackground}
                                    alt="Descriptive alt text"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                                <Typography sx={{ position: 'absolute', right: '15%', top: '23%', color: 'white', maxWidth: '30%', fontWeight: 600, fontSize: '22px', textAlign: 'left' }}>
                                    (REGISTER FOR EARLY ACCESS)
                                    CHOOSE A PROMPT ABOVE TO ASK OUR AI ABOUT THE SPECIFIC INFORMATION
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                )}
        </Paper>
    );
};

export default AskAI;