import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

const getSimilarCompaniesBasedOnScore = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Score/SimilarCompanies?symbol=${symbol}`;
  const response = await axios.get(URL);
  return response.data;
};

const ScoreService = {
  getSimilarCompaniesBasedOnScore
};
export default ScoreService;
