import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSpacingParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import CancelIcon from '@mui/icons-material/Close'; import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import {
  Paper, LinearProgress,
  CircularProgress,
  Box,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMutationsContext } from '../../../../contexts/MutationsContext';
import usePortfolioNames from '../../../../hooks/usePortfolioNames';
import EditToolbar from '../../transactions/components/EditToolbar';
import PortfolioAnalysisService from '../../../../services/portfolioAnalysisService';
import { useNavigate } from 'react-router-dom';
import CustomCell from '../../../../components/analyticsGrid/CustomCell';
import { UploadFile } from '@mui/icons-material';
import ImportTransactionsDialog from '../../../../components/analyticsGrid/Dialog/ImportTransactionsDialog/ImportTransactionsDialog';
import { useFreeResourcesExceededDialogContext } from '../../../../contexts/FreeResourcesExceededDialogContext';

export default function PortfoliosGrid() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: portfolios, isFetching } = usePortfolioNames();
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowIsInWaitingMode, setRowIsInWaitingMode] = useState<any>();
  const [importPoftfolioId, setImportPoftfolioId] = useState<number>(0);
  const [importPoftfolioName, setImportPoftfolioName] = useState<string>('');
  const [isImportOpen, setIsImportOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });
  const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();

  useEffect(() => {
    if (portfolios) {
      setRows(portfolios.map((row: GridRowModel) => (
        { ...row }
      )));
    }
  }, [isFetching, portfolios]);

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    };
  }, []);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  // const handleDeleteClick = (params: any) => () => {
  //   setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== params.id)));
  //   deleteTransactionById.mutate(params);
  // };

  const handleSaveClick = (params: any) => () => {
    setRowModesModel((oldModel) => ({ ...oldModel, [params.row.id]: { mode: GridRowModes.View } }));
    setRowIsInWaitingMode((oldModel: any) => ({ ...oldModel, [params.id]: true }));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== id)));
    }
  };


  function updateRows(newRows: GridRowModel[]) {
    queryClient.setQueryData(['getPortfolioNames'], newRows);
    return newRows;
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    postUpsertPortfolio.mutate(newRow);
    return newRow;
  };

  const postUpsertPortfolio = useMutation((data: any) => (
    PortfolioAnalysisService.postNewPortfolio(data)), {
    onMutate: ((variables: any) => {
      const updatedRow = { ...variables, isNew: false };
      return { updatedRow };
    }),
    onError: ((error: any, variables: any) => {
      if (error?.response?.status == 403) {
        freeResourcesExceededDialogContext.setDialogText('PORTFOLIOS');
        freeResourcesExceededDialogContext.setIsDialogOpened(true);
      }
      else {
        enqueueSnackbar('Entered data is incorrect', { variant: 'error' });
      }
      setRowModesModel((oldModel) => (
        { ...oldModel, [variables.id]: { mode: GridRowModes.Edit } }
      ));

    }),
    onSuccess: ((data, variables: any, context: any) => {
      queryClient.refetchQueries(['getPortfolioNames']);
      setRows((oldRows) => updateRows(oldRows.map((row) => (
        row.id === variables.id ? { ...context.updatedRow, id: data.id } : row
      ))));
      enqueueSnackbar(`New ${variables.symbol} portfolio has been saved succesfully`);
    }),
    onSettled: ((data, error, variables: any) => (
      setRowIsInWaitingMode((oldModel: any) => ({ ...oldModel, [variables.id]: false }))
    )),
  });

  const deletePortfolioById = useMutation((params: any) => (
    PortfolioAnalysisService.deletePortfolioById(params.id)), {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries(['getPortfolioNames']);
      enqueueSnackbar(`${variables.row.symbol} portfolio has been deleted succesfully`);
    },
  });

  const handleDeleteClick = (params: any) => () => {
    setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== params.id)));
    deletePortfolioById.mutate(params);
  };

  const handleImportClick = (params: any) => () => {
    console.log(params);
    setImportPoftfolioName(params.row.name);
    setImportPoftfolioId(params.row.id);
    setIsImportOpen(!isImportOpen);
  };

  const handleDetailsClick = (params: any) => () => {
    navigate(`/portfolio/management/details/${params.row.name}`);
  };

  const handleEditClick = (id: GridRowId) => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const renderNameCell = (params: any) => {
    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      return (
        <Box display="flex" alignItems="center">
          <TextField
            value={params.value}
            onChange={(event: any) => {
              const newValue = event.target.value;
              setRows((prevRows) =>
                prevRows.map((row) =>
                  row.id === params.id ? { ...row, name: newValue } : row
                )
              );
            }}
            size="small"
            variant="outlined"
          />
          <IconButton size="small" onClick={handleSaveClick(params)}>
            <SaveIcon />
          </IconButton>
          <IconButton size="small" onClick={handleCancelClick(params.id)}>
            <CancelIcon />
          </IconButton>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          background: "rgba(240, 240, 240, 0.4)",
          width: '100%',
          height: '80%',
          marginLeft: '10px',
          marginRight: '40px',
          borderRadius: '8px',
          display: 'flex', // Make the outer Box a flex container
          alignItems: 'center', // Vertically center content within the outer Box
          padding: '0 20px' // Add padding for spacing
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }} // Ensure the inner Box takes full width
        >
          <Box sx={{ marginLeft: '20px', minWidth: '150px' }}>
            <span>{params.value}</span>
          </Box>
          <IconButton size="small" onClick={() => handleEditClick(params.id)}>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      editable: false,
      width: isMobile ? 120 : 250,
      flex: 0,
      cellClassName: 'actions-column',
      headerClassName: 'actions-column-header',
      getActions: (params: GridRowParams) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Tooltip title="Save"><SaveIcon /></Tooltip>}
              label="Save"
              onClick={handleSaveClick(params)}
            />,
            <GridActionsCellItem
              icon={<Tooltip title="Cancel"><CancelIcon /></Tooltip>}
              label="Cancel"
              onClick={handleCancelClick(params.id)}
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Tooltip title="Details"><InfoIcon /></Tooltip>}
            label="Details"
            onClick={handleDetailsClick(params)}
          />,
          <GridActionsCellItem
            icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
            label="Delete"
            onClick={handleDeleteClick(params)}
          />,
          <GridActionsCellItem
            icon={<Tooltip title="Import"><UploadFile /></Tooltip>}
            label="Import"
            onClick={handleImportClick(params)}
          />,
        ];
      },
    },
    {
      field: 'name',
      type: 'string',
      headerAlign: 'left',
      headerName: 'Name',
      editable: true,
      flex: 1,
      renderCell: renderNameCell
    },
    {
      field: 'companiesNumber',
      type: 'string',
      headerAlign: 'left',
      headerName: 'Companies number',
      editable: false,
      flex: 1,
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
  ];

  return (
    <Paper sx={{
      width: '95%', flexFlow: 'column', flex: '0 1 auto'
    }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        density="standard"
        editMode="row"
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 15]}
        autoHeight
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        getRowSpacing={getRowSpacing}
        processRowUpdate={processRowUpdate}
        onRowModesModelChange={(newModel) => (
          setRowModesModel((oldModel) => ({ ...oldModel, ...newModel }))
        )}
        getRowId={(row) => row.id}
        components={{
          Toolbar: EditToolbar,
          LoadingOverlay: LinearProgress,
        }}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            setRows, setRowModesModel, setRowIsInWaitingMode,
          },
        }}
      />
      <ImportTransactionsDialog isOpen={isImportOpen} setIsOpen={setIsImportOpen} portfolioId={importPoftfolioId} portfolioName={importPoftfolioName} />

    </Paper>
  );
}
