import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSpacingParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import CancelIcon from '@mui/icons-material/Close'; import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import {
  Paper, LinearProgress,
  CircularProgress,
  Box,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import useWatchlistNames from '../../../hooks/useWatchlistNames';
import WatchlistService from '../../../services/watchlistService';
import EditToolbar from '../../Portfolio/transactions/components/EditToolbar';
import { ManageSearch } from '@mui/icons-material';
import CustomCell from '../../../components/analyticsGrid/CustomCell';
import { useFreeResourcesExceededDialogContext } from '../../../contexts/FreeResourcesExceededDialogContext';

export default function WatchlistsGrid() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: watchlists, isFetching } = useWatchlistNames();
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowIsInWaitingMode, setRowIsInWaitingMode] = useState<any>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });
  const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (watchlists) {
      setRows(watchlists.map((row: GridRowModel) => (
        { ...row }
      )));
    }
  }, [isFetching, watchlists]);

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    };
  }, []);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  // const handleDeleteClick = (params: any) => () => {
  //   setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== params.id)));
  //   deleteTransactionById.mutate(params);
  // };

  const handleSaveClick = (params: any) => () => {
    setRowModesModel((oldModel) => ({ ...oldModel, [params.row.id]: { mode: GridRowModes.View } }));
    setRowIsInWaitingMode((oldModel: any) => ({ ...oldModel, [params.id]: true }));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== id)));
    }
  };


  function updateRows(newRows: GridRowModel[]) {
    queryClient.setQueryData(['getWatchlistNames'], newRows);
    return newRows;
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    postUpsertWatchlist.mutate(newRow);
    return newRow;
  };

  const postUpsertWatchlist = useMutation((data: any) => (
    WatchlistService.postUpsertWatchlist(data)), {
    onMutate: ((variables: any) => {
      const updatedRow = { ...variables, isNew: false };
      return { updatedRow };
    }),
    onError: ((error: any, variables: any) => {
      if (error?.response?.status == 403){
        freeResourcesExceededDialogContext.setDialogText('WATCHLISTS');
        freeResourcesExceededDialogContext.setIsDialogOpened(true);
      }
      else{
        enqueueSnackbar('Entered data is incorrect', { variant: 'error' });
      }
      
      setRowModesModel((oldModel) => (
        { ...oldModel, [variables.id]: { mode: GridRowModes.Edit } }
      ));
    }),
    onSuccess: ((data, variables: any, context: any) => {
      queryClient.refetchQueries(['getWatchlistNames']);
      setRows((oldRows) => updateRows(oldRows.map((row) => (
        row.id === variables.id ? { ...context.updatedRow, id: data.id } : row
      ))));
      enqueueSnackbar(`New watchlist has been saved succesfully`);
    }),
    onSettled: ((data, error, variables: any) => (
      setRowIsInWaitingMode((oldModel: any) => ({ ...oldModel, [variables.id]: false }))
    )),
  });

  const deleteWatchlistById = useMutation((params: any) => (
    WatchlistService.deleteWatchlistById(params.id)), {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries(['getWatchlistNames']);
      enqueueSnackbar(`The watchlist has been deleted succesfully`);
    },
  });

  const handleDeleteClick = (params: any) => () => {
    setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== params.id)));
    deleteWatchlistById.mutate(params);
  };

  const handleScreenerClick = (params: any) => () => {
    navigate(`/stockScreener/screener?watchlistName=${params.row.name}`);
  };

  const handleEditClick = (id: GridRowId) => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const renderNameCell = (params: any) => {
    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      return (
        <Box display="flex" alignItems="center">
          <TextField
            value={params.value}
            onChange={(event: any) => {
              const newValue = event.target.value;
              setRows((prevRows) =>
                prevRows.map((row) =>
                  row.id === params.id ? { ...row, name: newValue } : row
                )
              );
            }}
            size="small"
            variant="outlined"
          />
          <IconButton size="small" onClick={handleSaveClick(params)}>
            <SaveIcon />
          </IconButton>
          <IconButton size="small" onClick={handleCancelClick(params.id)}>
            <CancelIcon />
          </IconButton>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          background: "rgba(240, 240, 240, 0.4)",
          width: '100%',
          height: '80%',
          marginLeft: '10px',
          marginRight: '40px',
          borderRadius: '8px',
          display: 'flex', // Make the outer Box a flex container
          alignItems: 'center', // Vertically center content within the outer Box
          padding: '0 20px' // Add some padding for better spacing
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ width: '100%' }} // Ensure inner Box takes full width
        >
          <Box sx={{ marginRight: 'auto', minWidth: '150px' }}> {/* Adjusted to marginRight for spacing */}
            <span>{params.value}</span>
          </Box>
          <IconButton size="small" onClick={() => handleEditClick(params.id)}> {/* Ensure the onClick function is properly bound */}
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      editable: false,
      width: isMobile ? 100 : 250,
      flex: 0,
      cellClassName: 'actions-column',
      headerClassName: 'actions-column-header',
      getActions: (params: GridRowParams) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Tooltip title='Save'><SaveIcon /></Tooltip>}
              label="Save"
              onClick={handleSaveClick(params)}
            />,
            <GridActionsCellItem
              icon={<Tooltip title='Cancel'><CancelIcon /></Tooltip>}
              label="Cancel"
              onClick={handleCancelClick(params.id)}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Tooltip title='Details'><ManageSearch /></Tooltip>}
            label="Details"
            onClick={handleScreenerClick(params)}
          />,
          <GridActionsCellItem
            icon={<Tooltip title='Delete'><DeleteIcon /></Tooltip>}
            label="Delete"
            onClick={handleDeleteClick(params)}
          />,
        ];
      },
    },
    {
      field: 'name',
      type: 'string',
      headerAlign: 'left',
      headerName: 'Name',
      editable: true,
      flex: 1,
      renderCell: renderNameCell
    },
    {
      field: 'companiesNumber',
      type: 'string',
      headerAlign: 'left',
      headerName: 'Companies number',
      editable: false,
      flex: 1,
      renderCell: (params: any) => (
        <CustomCell value={params.value} />
      )
    },
    // {
    //   field: 'weeklyChange',
    //   type: 'number',
    //   headerAlign: 'left',
    //   headerName: 'Weekly change',
    //   editable: false,
    //   flex: 1,
    // },
    // {
    //   field: 'monthlyChange',
    //   type: 'number',
    //   headerAlign: 'left',
    //   headerName: 'Monthly change',
    //   editable: false,
    //   flex: 1,
    // },
  ];

  return (
    <Paper sx={{
      width: '95%', flexFlow: 'column', flex: '0 1 auto'
    }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        density="standard"
        editMode="row"
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 15]}
        autoHeight
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        getRowSpacing={getRowSpacing}
        processRowUpdate={processRowUpdate}
        onRowModesModelChange={(newModel) => (
          setRowModesModel((oldModel) => ({ ...oldModel, ...newModel }))
        )}
        getRowId={(row) => row.id}
        components={{
          Toolbar: EditToolbar,
          LoadingOverlay: LinearProgress,
        }}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            setRows, setRowModesModel, setRowIsInWaitingMode,
          },
        }}
      />
    </Paper>
  );
}
