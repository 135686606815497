import { Box, Typography, Container, Paper, Grid } from '@mui/material';

export default function TopSTI() {
  return (
    <Box sx={{ width: '100%', py: 4 }}>
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Top STI Scores
          </Typography>
          <Typography variant="subtitle1" paragraph align="center" sx={{ mb: 4 }}>
            Explore companies with the highest STI (Stock Trade IQ) scores - our proprietary scoring mechanism for investment opportunities.
          </Typography>
          
          <Grid container spacing={3}>
            {/* This is a placeholder. You'll want to fetch and display actual data here */}
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary" align="center">
                Loading top STI scores...
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}