import React, { useState } from 'react';
import { Box, Button, IconButton, InputBase, Menu, MenuItem, Paper, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import StocksSearch from '../../components/StockSearch/StocksSearch';
import ROUTES from '../../constants/routes';
import { useLocation } from 'react-router-dom';
import { Search } from '@mui/icons-material';

interface UnauthenticatedNavProps {
    navigate: (path: string) => void;
    instance: {
        loginRedirect: (request: any) => void;
    };
    loginRequest: any;
    isBasePath: boolean;
    DocumentsMenu: React.ComponentType;
}

const UnauthorizedUserMenu: React.FC<UnauthenticatedNavProps> = ({
    navigate,
    instance,
    loginRequest,
    isBasePath,
    DocumentsMenu
}) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchMenuIsOpen, setSearchMenuIsOpen] = useState<boolean>(false);
    const [anchorUserEl, setAnchorUserEl] = useState<null | HTMLElement>(null);
    const [anchorDocEl, setAnchorDocEl] = useState<null | HTMLElement>(null);
    const [anchorSearchEl, setAnchorSearchEl] = useState(null);

    const theme: Theme = useTheme();
    const location = useLocation();
    const showHeaderSearch = location.pathname !== '/' && !location.pathname.includes('stockScreener/screener');
    const isMobile = useMediaQuery('(max-width:600px)');


    function handleClickMenuItem(companySymbol: string) {
        if (!companySymbol || companySymbol === '') return;

        // Close the search menu
        setSearchMenuIsOpen(false);

        // Also close the mobile menu if it's open
        if (anchorSearchEl) {
            handleSearchClose();
        }

        // Navigate to the stock page
        navigate(ROUTES.stockDetailedData(companySymbol));
    }

    function handleChangeTextFieldValue(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(() => e.target.value);
        setSearchMenuIsOpen(() => true);
    }

    const handleSearchMenu = (event: any) => {
        setAnchorSearchEl(event.currentTarget);
    };

    const handleSearchClose = () => {
        setAnchorSearchEl(null);
    };

    const mobileMenuStyle = {
        '& .MuiMenu-paper': {
            width: '300px',
            maxWidth: 'calc(100vw - 32px)',
        },
        '& .MuiBackdrop-root': {
            position: 'absolute'
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            flexDirection: { xs: 'row' },
            alignItems: { xs: 'flex-end', xl: 'center' },
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: { xs: 'flex-end', xl: 'flex-start' },
                gap: 2,
                width: { xs: '100%', xl: 'auto' }
            }}>
                {showHeaderSearch && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: isMobile ? 0 : '8px' }}>
                        {!isMobile && (<StocksSearch
                            inputValue={searchValue}
                            onClickMenuItem={handleClickMenuItem}
                            searchMenuIsOpen={searchMenuIsOpen}
                            setSearchMenuIsOpen={setSearchMenuIsOpen}
                        >
                            <Paper
                                style={{
                                    borderRadius: '15px',
                                    boxShadow: '2px 8px 12.4px #00000008',
                                    height: '36px',
                                    width: '240px',
                                    border: '1px solid #0000001A',
                                    marginTop: '2px'
                                }}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '5px 10px 5px 0',
                                    position: 'relative',
                                    alignItems: 'center',
                                    backgroundColor: '#F9F9F912'
                                }}
                            >
                                <InputBase
                                    value={searchValue}
                                    placeholder="Search for your stock..."
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextFieldValue(e)}
                                    sx={{ width: '220px', marginLeft: '20px', color: 'black' }}
                                />
                            </Paper>
                        </StocksSearch>
                        )}

                        {isMobile && (
                            <>
                                <IconButton
                                    size="large"
                                    onClick={handleSearchMenu}
                                    color="inherit"
                                    style={{ marginRight: '8px' }}
                                >
                                    <Search />
                                </IconButton>

                                <Menu
                                    anchorEl={anchorSearchEl}
                                    open={Boolean(anchorSearchEl)}
                                    onClose={handleSearchClose}
                                    style={{ zIndex: theme.zIndex.tooltip }}
                                    sx={mobileMenuStyle}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    disablePortal={false}
                                    disableScrollLock={true}
                                    container={document.body}
                                >
                                    <MenuItem sx={{ padding: 0, zIndex: theme.zIndex.tooltip + 300 }}>
                                        <StocksSearch
                                            inputValue={searchValue}
                                            onClickMenuItem={handleClickMenuItem}
                                            searchMenuIsOpen={searchMenuIsOpen}
                                            setSearchMenuIsOpen={setSearchMenuIsOpen}
                                        >
                                            <Paper
                                                style={{
                                                    borderRadius: '15px',
                                                    boxShadow: 'none',
                                                    height: '36px',
                                                    width: '100%',
                                                    border: '1px solid #0000001A',
                                                    backgroundColor: '#F9F9F912'
                                                }}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    padding: '5px 10px 5px 0',
                                                    position: 'relative',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <InputBase
                                                    autoFocus
                                                    value={searchValue}
                                                    placeholder="Search for your stock..."
                                                    onChange={handleChangeTextFieldValue}
                                                    sx={{
                                                        width: '100%',
                                                        marginLeft: '20px',
                                                        color: 'black'
                                                    }}
                                                />
                                            </Paper>
                                        </StocksSearch>
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </Box>
                )}

                <Button
                    variant="text"
                    sx={{
                        minWidth: 'auto',
                        padding: '12px',
                        color: 'inherit'
                    }}
                    onClick={() => instance.loginRedirect(loginRequest)}
                >
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '0.874rem',
                        textTransform: 'none',
                    }}>
                        Login
                    </Typography>
                </Button>
            </Box>

            {/* Sign up button that can wrap */}
            <Box sx={{
                display: { xs: 'none', xl: 'flex' },
                alignItems: 'center',
                width: 'auto',
                justifyContent: 'flex-start'
            }}>
                {!isBasePath && <Button
                    sx={{
                        padding: { xs: '3px 50px', xl: '12px 50px' },
                        textTransform: 'none',
                        borderRadius: '50px',
                        background: 'linear-gradient(90deg, #330867 0%, #30CFD0 100%)',
                        color: 'white',
                        minWidth: 'auto'
                    }}
                    variant="contained"
                    size="medium"
                    onClick={() => instance.loginRedirect(loginRequest)}
                    aria-label="Sign up button"
                >
                    Sign up for free
                </Button>}
            </Box>
        </Box>
    );
};

export default UnauthorizedUserMenu;