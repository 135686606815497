import { Box, Divider, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import BlogService from '../../services/blogService';
import BlogOverviewDto from '../../models/dtos/blogOverviewDto';
import Loader from '../../components/Loader';
import BlogOverview from './components/BlogOverview';
import BlogOverviewMobile from './components/BlogOverviewMobile';

export default function BlogSOverview() {
  const { data: blogOverviews } = useQuery(['getblogOverviews'], BlogService.getBlogArticlesOverviews);
  const isMobile = useMediaQuery('(max-width:600px)');


  return (
    <Box sx={{
      padding: '20px', marginTop: '50px', width: '100%', gap: '20px', display: 'flex', flexDirection: 'column',
    }}
    >
      <Helmet>
        <title />
      </Helmet>
      <Box sx={{ width: {xs: '90%', lg: '80%'}, margin: {xs: '5%', lg: '20%'} }}>
        <Stack spacing={1} divider={<Divider flexItem />}>
          {blogOverviews ? blogOverviews.map((data: BlogOverviewDto) => (
              isMobile ? 
              (<BlogOverviewMobile data={data} />) :
              (<BlogOverview data={data} />)
          ))
            : <Box sx={{ width: '100%' }} className="center-items"><Loader /></Box>}
        </Stack>
      </Box>
    </Box>
  );
}
