import { AccountCircle, Gavel, Search } from '@mui/icons-material';
import {
  Box,
  IconButton, InputBase, Menu, MenuItem, Paper, Theme, Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@mui/styles';
import { useAccount, useMsal } from '@azure/msal-react';
import { useColorModeContext } from '../../contexts/ColorModeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import SubscriptionService from '../../services/subscriptionService';
import StocksSearch from '../../components/StockSearch/StocksSearch';

export default function AuthorizedUserMenu() {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});


  const [anchorUserEl, setAnchorUserEl] = useState<null | HTMLElement>(null);
  const [anchorDocEl, setAnchorDocEl] = useState<null | HTMLElement>(null);
  const [anchorSearchEl, setAnchorSearchEl] = useState(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchMenuIsOpen, setSearchMenuIsOpen] = useState<boolean>(false);

  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:600px)');
  const showHeaderSearch = location.pathname !== '/' && !location.pathname.includes('stockScreener/screener');


  const { instance } = useMsal();
  const theme: Theme = useTheme();
  const colorMode = useColorModeContext();

  const handleDocMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDocEl(event.currentTarget);
  };

  const handleSearchMenu = (event: any) => {
    setAnchorSearchEl(event.currentTarget);
  };

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setAnchorUserEl(null);
  };

  const handleDocClose = () => {
    setAnchorDocEl(null);
  };

  const handleSearchClose = () => {
    setAnchorSearchEl(null);
  };

  const handleSignOut = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    setAnchorUserEl(null);
  };

  function handleClickMenuItem(companySymbol: string) {
    if (companySymbol === '') return;
    navigate(ROUTES.stockDetailedData(companySymbol));
    setSearchMenuIsOpen(() => false);
  }

  function handleChangeTextFieldValue(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(() => e.target.value);
    setSearchMenuIsOpen(() => true);
  }

  const mobileMenuStyle = {
    '& .MuiMenu-paper': {
      width: '300px',
      maxWidth: 'calc(100vw - 32px)',
    },
    '& .MuiBackdrop-root': {
      position: 'absolute',
    }
  };

  const handleManageSubscription = async () => {
    const email: string = account?.idTokenClaims?.email as string;

    if (email) {
      const result = await SubscriptionService.getSubscriptionManagementUrl();

      if (result && typeof result.data === 'string') {
        const url = result.data;
        const isAbsoluteUrl = /^https?:\/\//i.test(url);

        if (isAbsoluteUrl) {
          window.open(url, '_blank');
        } else {
          navigate(url);
        }
      }
    }

    setAnchorUserEl(null);
  };

  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      {showHeaderSearch && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: isMobile ? 0 : '8px' }}>
          {!isMobile && (<StocksSearch
            inputValue={searchValue}
            onClickMenuItem={handleClickMenuItem}
            searchMenuIsOpen={searchMenuIsOpen}
            setSearchMenuIsOpen={setSearchMenuIsOpen}
          >
            <Paper
              style={{
                borderRadius: '15px',
                boxShadow: '2px 8px 12.4px #00000008',
                height: '36px',
                width: '240px',
                border: '1px solid #0000001A',
                marginTop: '2px'
              }}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '5px 10px 5px 0',
                position: 'relative',
                alignItems: 'center',
                backgroundColor: '#F9F9F912'
              }}
            >
              <InputBase
                value={searchValue}
                placeholder="Search for your stock..."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextFieldValue(e)}
                sx={{ width: '220px', marginLeft: '20px', color: 'black' }}
              />
            </Paper>
          </StocksSearch>
          )}
          
          {isMobile && (
            <>
              <IconButton
                size="large"
                onClick={handleSearchMenu}
                color="inherit"
                style={{ marginRight: '8px' }}
              >
                <Search />
              </IconButton>

              <Menu
                anchorEl={anchorSearchEl}
                open={Boolean(anchorSearchEl)}
                onClose={handleSearchClose}
                style={{ zIndex: theme.zIndex.tooltip }}
                sx={
                  mobileMenuStyle
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                disablePortal={false}
                disableScrollLock={true}
                container={document.body}
              >
                <MenuItem sx={{ padding: 0 }}>
                  <StocksSearch
                    inputValue={searchValue}
                    onClickMenuItem={(symbol: any) => {
                      handleClickMenuItem(symbol);
                      handleSearchClose();
                    }}
                    searchMenuIsOpen={searchMenuIsOpen}
                    setSearchMenuIsOpen={setSearchMenuIsOpen}
                  >
                    <Paper
                      style={{
                        borderRadius: '15px',
                        boxShadow: 'none',
                        height: '36px',
                        width: '100%',
                        border: '1px solid #0000001A',
                        backgroundColor: '#F9F9F912'
                      }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '5px 10px 5px 0',
                        position: 'relative',
                        alignItems: 'center',
                      }}
                    >
                      <InputBase
                        autoFocus
                        value={searchValue}
                        placeholder="Search for your stock..."
                        onChange={handleChangeTextFieldValue}
                        sx={{
                          width: '100%',
                          marginLeft: '20px',
                          color: 'black'
                        }}
                      />
                    </Paper>
                  </StocksSearch>
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      )}
      
      <div>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleUserMenu}
          color="inherit"
          style={{ borderRadius: '0px' }}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorUserEl}
          style={{ zIndex: theme.zIndex.tooltip }}
          sx={mobileMenuStyle}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorUserEl)}
          onClose={handleUserClose}
          disableScrollLock={true}
        >
          <MenuItem onClick={handleManageSubscription}>Your subscriptions</MenuItem>
          <MenuItem sx={{ minWidth: '300px' }} onClick={handleSignOut}>Sign out</MenuItem>
        </Menu>
      </div>
    </div>
  );
}