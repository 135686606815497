import { Box, Button, Link, Paper, Typography, useMediaQuery } from '@mui/material';
import useGlobalStyles from '../../../styles/useGlobalStyles';
import STIMock from '../../../assets/STIMock.png'
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';
import { useFreeResourcesExceededDialogContext } from '../../../contexts/FreeResourcesExceededDialogContext';

export default function StockUnauthorizedAdvancedKeyTakeaways() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();
  const isMobile = useMediaQuery('(max-width:600px)');


  const OnCheckClicked = () => {
    if (isAuthenticated) {
      freeResourcesExceededDialogContext.setDialogText('STI score calculation');
      freeResourcesExceededDialogContext.setIsDialogOpened(true);
    } else {
      instance.loginRedirect(loginRequest);
    }
  }

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden', // This ensures the pseudo-element stays within Paper bounds
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${STIMock})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(15px)', // Add blur effect
          zIndex: 0,
        }
      }}
      elevation={7}
    >
      <Box
        sx={{
          width: '100%',
          minHeight: '350px',
          flexGrow: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          position: 'relative',
          zIndex: 1, // Ensure content appears above the blurred background
        }}
        className="center-items"
      >
        <Paper
          elevation={7}
          sx={{
            width: '50%',
            height: '50%',
            backgroundColor: 'rgb(249, 249, 249)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '150px'
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px'  // Space between text and button
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center'
              }}
            >
              {!isMobile && (
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '20px'
                  }}>
                  Check the
                </Typography>

              )
              }
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '20px'
                }}>
                KEY TAKEAWAYS
              </Typography>
            </Box>
            <Button
              onClick={OnCheckClicked}
              variant="contained"
              sx={{
                backgroundColor: '#9747FF',
                '&:hover': {
                  backgroundColor: '#7939CC'
                }
              }}
            >
              CHECK
            </Button>
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
}
