import { Box, Paper, Typography } from '@mui/material';
import BasicTable from '../../../../../components/BasicTable/BasicTable';
import useCompanyOverview from '../../../../hooks/useCompanyOverview';

interface TechnicalTablePropsType{
  stockSymbol: string | undefined,
  width: string,
  paperHeight: string
}

export default function TechnicalTable({ stockSymbol, width, paperHeight }: TechnicalTablePropsType) {
  const {
    data: companyOverview,
    isLoading: isLoadingCompanyOverview,
  } = useCompanyOverview(stockSymbol as string);

  return (
    <Box sx={{ width, maxWidth: '100%' }}>
      <Typography variant="h6" sx={{ padding: '5px 0px 5px 15px', width: '100%', textAlign: 'left', fontSize: '18px', fontWeight: '700' }}>
        TECHNICAL
      </Typography>
      <Paper sx={{ width, maxWidth: '100%', minWidth: width, height: paperHeight, padding: '20px' }} elevation={4}>
        <BasicTable
          data={{
            '52 weeks high': companyOverview?.yearHigh,
            '52 weeks low': companyOverview?.yearLow,
            'Current trading session High': companyOverview?.dayHigh,
            'Current trading session Low': companyOverview?.dayLow,
          }}
          isLoading={isLoadingCompanyOverview}
        />
      </Paper>
    </Box>
  );
}
