import { Height } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Link, Typography } from "@mui/material";
import { loginRequest } from "../../../authConfig";
import { useMsal } from "@azure/msal-react";
import decreaseInvestmentRiskImage from "../../../assets/decreaseInvestmentRisk.png"
import uniqueAlertsImage from "../../../assets/uniqueAlerts.png"
import tailoredDatagridImage from "../../../assets/tailoredDataGrid.png"

export default function DedicatedFunctionsForEffectiveWorkMobile() {
    const imageWidthPercent = 100;

    const { instance } = useMsal();

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '100vh',
                position: 'relative',
                overflow: 'hidden',
                background: `white`
            }}
        >
            <Box sx={{ padding: '5%', marginTop: '10%' }}>
                <Typography
                    sx={{
                        fontSize: '26px',
                        fontFamily: 'Calibre, sans-serif',
                        fontWeight: '500',
                        marginBottom: '16px',
                        color: 'black',
                        textAlign: 'center',
                        zIndex: '30',
                        position: 'relative'
                    }}
                >
                    Make intelligent investment decisions with us
                </Typography>
                <Box sx={{ padding: '5%', borderRadius: '20px', textAlign: 'center', background: '#DBE6FA' }}>
                    <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '600', lineHeight: '48px', marginBottom: '20px' }}>
                        Original scoring mechanism
                    </Typography>
                    <Box
                        sx={{
                            position: 'relative',
                            width: `${imageWidthPercent}%`,
                            paddingBottom: `${(1391 / 2455) * imageWidthPercent}%`,
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: 20
                            }}
                            alt="Decrease investment risk with our key takeaways"
                            src={decreaseInvestmentRiskImage}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -45,
                                right: -55,
                                width: '10000px',
                                height: '130%',
                                objectFit: 'cover',
                                zIndex: 10,
                                borderRadius: '20px'
                            }}
                        />
                    </Box>

                    <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '400', lineHeight: '18px' }}>
                        Stock screener with original scoring and key takeaway mechanism. Search for investment opportunities worldwide and save time for analysis by using our key takeaways and scoring to quickly identify the most interesting insights regarding a browsed company based on all the available historical data.
                    </Typography>

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '2%'
                    }}>
                        <Link
                            href="/blog/original-scoring-mechanism"
                            sx={{
                                color: 'rgb(48, 207, 208)',
                                textDecoration: 'none',
                            }}
                        >
                            LEARN MORE
                        </Link>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ padding: '5%', marginTop: '10%' }}>
                <Box sx={{ padding: '5%', borderRadius: '20px', textAlign: 'center', background: '#EBD3F5' }}>
                    <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '600', lineHeight: '48px', marginBottom: '20px' }}>
                        Unique fundamental alerts
                    </Typography>
                    <Box
                        sx={{
                            position: 'relative',
                            width: `${imageWidthPercent}%`,
                            paddingBottom: `${(1391 / 2455) * imageWidthPercent}%`,
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: 20,
                                borderRadius: '20px'
                            }}
                            alt="Unique alerts image"
                            src={uniqueAlertsImage}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -45,
                                right: -55,
                                width: '10000px',
                                height: '130%',
                                objectFit: 'cover',
                                zIndex: 10,
                                borderRadius: '20px'
                            }}
                        />
                    </Box>

                    <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '400', lineHeight: '18px', marginTop: '15px' }}>
                        Price and fundamental alerts elevate value investing to a new level. Whenever you browse for a company that will have certain indicators at an expected level, now you can save time by setting up alerts that will help you figure out whenever a company of your interest reaches the expected price level or business performance.
                    </Typography>

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '2%'
                    }}>
                        <Link
                            href="/blog/fundamental-alerts"
                            sx={{
                                color: 'rgb(48, 207, 208)',
                                textDecoration: 'none',
                            }}
                        >
                            LEARN MORE
                        </Link>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ padding: '5%', marginTop: '10%' }}>
                <Box sx={{ padding: '5%', borderRadius: '20px', textAlign: 'center', background: '#DBE6FA' }}>
                    <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '600', lineHeight: '18px', marginBottom: '20px' }}>
                        Stock screener tailored for dividend investors
                    </Typography>
                    <Box
                        sx={{
                            position: 'relative',
                            width: `${imageWidthPercent}%`,
                            paddingBottom: `${(1391 / 2455) * imageWidthPercent}%`,
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: 20,
                                borderRadius: '20px'
                            }}
                            alt="Tailored datagrid image"
                            src={tailoredDatagridImage}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -45,
                                right: -55,
                                width: '10000px',
                                height: '130%',
                                objectFit: 'cover',
                                zIndex: 10,
                                borderRadius: '20px'
                            }}
                        />
                    </Box>

                    <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '400', lineHeight: '18px', marginTop: '15px' }}>
                        Stock screener that has been tailored to help you do your research using value investing principles, especially for dividend companies. Moreover, as a dividend investor, you can set your dividend payout goal and analyze investment opportunities by the goal.
                    </Typography>

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '2%'
                    }}>
                        <Link
                            href="/blog/stock-screener-for-dividend-investing"
                            sx={{
                                color: 'rgb(48, 207, 208)',
                                textDecoration: 'none',
                            }}
                        >
                            LEARN MORE
                        </Link>
                    </Box>
                </Box>
            </Box>

            <Grid container spacing={10} style={{ paddingLeft: '10%', paddingRight: '5%', marginBottom: '40px', marginTop: '0px', position: 'relative' }}>
                <Grid item xs={12} md={7}>

                    <Button
                        style={{
                            padding: '12px 50px',
                            margin: '10px',
                            textTransform: 'none',
                            borderRadius: '10px',  // Rounded edges
                            background: '#30CFD0',  // Gradient background
                            color: 'white',
                            fontSize: '20px',
                            boxShadow: '2px 8px 12.4px 1px #00000021'
                        }}
                        variant="contained"
                        size="large"
                        onClick={() => instance.loginRedirect(loginRequest)}
                        aria-label="I’m starting for free button"
                    >
                        I’m starting for free
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}