import {
  ListItem, ListItemIcon, Box, ListItemText, Link,
  useMediaQuery,
} from '@mui/material';
import { ArrowRight, SvgIconComponent } from '@mui/icons-material';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { useMemo, useState } from 'react';
import './MenuItem.css';
import clsx from 'clsx';
import CircleIcon from '@mui/icons-material/Circle';
import { useDrawerContext } from '../../contexts/DrawerContext';
import { useLoaderContext } from '../../contexts/LoaderContext';
import useMenuItemStyles from '../styles/useMenuItemStyles';
import useToolbarStyles from '../styles/useToolbarStyles';
import { SubmenuItem } from '../DrawerSubmenuItem';

interface MenuItemProps {
  routePrefix?: string;
  route?: string;
  literal: string;
  Icon: SvgIconComponent;
  submenu: Array<SubmenuItem>;
  expandUp?: boolean;
}

export function MenuItem({
  route = '', routePrefix = '', literal, Icon, submenu, expandUp = false,
}: MenuItemProps) {
  const [nested, setNested] = useState(false);
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setIsLoaderOpened } = useLoaderContext();
  const menuItemClasses = useMenuItemStyles();
  const toolbarClasses = useToolbarStyles();
  const isMobile = useMediaQuery('(max-width:600px)');


  function changeRoute(newRoute: string) {
    if (pathname !== newRoute) {
      setIsLoaderOpened(() => false);
    }

    if (isMobile){
      toggleIsOpened(false);
    }
    navigate(`${newRoute}`);
  }

  function expandOrNavigate() {
    if (submenu.length === 0) {
      changeRoute(route as string);
    } else {
      if (!isOpened) {
        toggleIsOpened(true);
        setNested(true);
      }

      if (!pathname.includes(routePrefix)) {
        changeRoute(`${routePrefix as string}/${route as string}`);
        setNested(true);
      } else {
        setNested(!nested);
      }
    }
    smoothScrollToTop();
  }

  const smoothScrollToTop = () => {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
      window.requestAnimationFrame(smoothScrollToTop);
      window.scrollTo(0, currentScroll - currentScroll / 8);
    }
  };

  function hideArrow() {
    return submenu.length === 0 || !isOpened;
  }

  const Submenu = useMemo(() => (
    <Box className={nested && isOpened ? '' : 'hide'}>
      {submenu.map(({ route: submenuRoute, literal: submenuLiteral, Icon }) => (
        <ListItem
          button
          selected={pathname === submenuRoute}
          className="list-item nested"
          href={submenuRoute as string}
          onClick={() => changeRoute(submenuRoute as string)}
          classes={{
            root: menuItemClasses.listItem,
          }}
          key={submenuLiteral}
          aria-label={`${literal} ${submenuLiteral}`}
        >
          <CircleIcon className="nested-menu-icon" sx={{ fontSize: '5px' }} />
          <Icon sx={{ fontSize: '20px', marginLeft: '10px', marginRight: '10px' }} />
          <Link component={RouterLink} sx={{ color: '#707070' }} underline="none" to={(submenuRoute as string)}>{submenuLiteral}</Link>
        </ListItem>
      ))}
    </Box>
  ), [pathname, nested, isOpened]);

  return (
    <Box>
      {expandUp && Submenu}
      <ListItem
        button
        className="list-item primary"
        selected={pathname === route}
        aria-label={literal}
        onClick={() => expandOrNavigate()}
        classes={{
          root: menuItemClasses.listItem,
        }}
      >
        <ListItemIcon
          className="list-icon"
        >
          <Icon className="icon" classes={{ root: toolbarClasses.icon }} />
        </ListItemIcon>
        <ListItemText classes={{ primary: 'item-text', root: toolbarClasses.text }} primary={literal} />
        <ListItemIcon
          className={clsx('nested-button', { hide: hideArrow() })}
        >
          <ArrowRight fontSize="large" className="icon" classes={{ root: toolbarClasses.icon }} />
        </ListItemIcon>
      </ListItem>
      {!expandUp && Submenu}
    </Box>
  );
}
