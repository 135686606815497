import { Theme, useTheme, styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box, Typography, useMediaQuery } from '@mui/material';
import BlogOverviewDto from '../../../models/dtos/blogOverviewDto';
import ROUTES from '../../../constants/routes';

interface BlogOverviewProps {
  data: BlogOverviewDto,
}

export default function BlogOverview({ data }: BlogOverviewProps) {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Link 
      component={RouterLink} 
      underline="none" 
      to={(`${ROUTES.blog}/${data.urlName}` as string)}
      sx={{ textDecoration: 'none' }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column' : 'row',
          padding: '20px',
        }}
      >
        <Box 
          sx={{ 
            flex: 2,
            color: theme.palette.text.primary 
          }}
        >
          <Typography 
            variant="h5" 
            component="h1"
            sx={{ 
              fontSize: '1.9em',
              marginBottom: '8px'
            }}
          >
            {data.title}
          </Typography>
          
          <Typography 
            sx={{ 
              fontSize: '0.9em',
              marginBottom: '15px',
              color: theme.palette.text.secondary 
            }}
          >
            {data.author}
          </Typography>
          
          <Box sx={{ color: theme.palette.text.secondary }}>
            <Typography>{data.overview}</Typography>
          </Box>
        </Box>
        
        <Box 
          sx={{ 
            flex: 1,
            margin: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <Box 
            component="img"
            src={data.overviewImageUrl} 
            alt={data.title}
            sx={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Box>
      </Box>
    </Link>
  );
}