import { Box, Paper, Typography } from '@mui/material';
import BasicTable from '../../../../../components/BasicTable/BasicTable';
import useCompanyOverview from '../../../../hooks/useCompanyOverview';

interface FundamentalFirstTablePropsType{
  stockSymbol: string | undefined,
  width : string,
  paperHeight: string
}

export default function FundamentalFirstTable(
  { stockSymbol, width, paperHeight }: FundamentalFirstTablePropsType,
) {
  const {
    data: companyOverview,
    isLoading: isLoadingCompanyOverview,
  } = useCompanyOverview(stockSymbol as string);

  return (
    <Box sx={{ width, maxWidth: '100%' }}>
      <Typography variant="h6" sx={{ padding: '5px 0px 5px 15px', width: '100%', textAlign: 'left', fontSize: '18px', fontWeight: '700' }}>
        FUNDAMENTALS
      </Typography>
      <Paper sx={{ width, maxWidth: '100%', height: paperHeight, padding: '20px' }} elevation={4}>
        <BasicTable
          data={{
            'P/E': companyOverview?.peRatioTTM,
            'P/S': companyOverview?.priceToSalesRatioTTM,
            'P/B': companyOverview?.pbRatioTTM,
            'Debt/Equity': companyOverview?.debtToEquityTTM,
            'EV/FCF': companyOverview?.evToFreeCashFlowTTM,
            'Price to operating cash flow': -1,
            'Price to free cash flow': -1,
            'EV/sales': companyOverview?.evToSalesTTM,
            'Earnings yield': companyOverview?.earningsYieldTTM,
            'Debt/assets': companyOverview?.debtToAssetsTTM,
          }}
          isLoading={isLoadingCompanyOverview}
        />
      </Paper>
    </Box>
  );
}
