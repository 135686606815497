import { MenuItem, Theme, Typography, useTheme } from '@mui/material';

interface StocksSearchMenuItemPropsModel {
  symbol: string,
  name: string,
  handleClickMenuItem: (symbol: string) => void,
}

export default function StocksSearchMenuItem({
  symbol,
  name,
  handleClickMenuItem,
}: StocksSearchMenuItemPropsModel) {
  const theme: Theme = useTheme();

  return (
    <MenuItem
      key={name}
      sx={{
        margin: '0', height: '40px', padding: '0 10px 0 10px', overflow: 'hidden', zIndex: 2000
      }}
      onClick={() => handleClickMenuItem(symbol)}
    >
      <Typography variant="body1" sx={{ marginRight: '15px' }}>
        {symbol}
      </Typography>
      <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }} variant="body2">
        {name}
      </Typography>
    </MenuItem>
  );
}
