import {
    Box,
    Typography,
    Card,
    Button,
    Paper,
    Grid,
    Backdrop,
    Fade
} from '@mui/material';
import { useState } from 'react';
import BasicTable from '../../../../components/BasicTable/BasicTable';
import useSimilarCompaniesBasedOnScore from '../../../../../../hooks/useSimilarCompaniesBasedOnScore';
import STIScore from '../../../../../../components/KeyTakeaways/components/STIScore';
import useGlobalStyles from '../../../../../../styles/useGlobalStyles';
import Loader from '../../../../../../components/Loader';
import ROUTES from '../../../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useFreeResourcesExceededDialogContext } from '../../../../../../contexts/FreeResourcesExceededDialogContext';
import { useDialogContext } from '../../../../../../contexts/DialogContext';
import crownIcon from '../../../../../../assets/crown.png';
import { loginRequest } from '../../../../../../authConfig';
import useSubscriptionLevel from '../../../../../../hooks/useSubscriptionLevel';
import SubscriptionService from '../../../../../../services/subscriptionService';
import { FunctionalityType } from '../../../../../../models/subscriptionConfig';

interface Company {
    symbol: string;
    name: string;
    stiScore: number;
    marketCap: string | number;
    industry: string;
    sector: string;
}

interface SimilarCompaniesCardProps {
    company: Company;
    isBest: boolean;
    isSecond: boolean;
    blurred: boolean;
    onReveal: () => void;
}

const SimilarCompaniesCard = ({ company, isBest, isSecond, blurred, onReveal }: SimilarCompaniesCardProps) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (!blurred) {
            navigate(ROUTES.stockDetailedData(company.symbol));
            window.scrollTo(0, 0);
        }
    };

    return (
        <Card
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '25px 15px 0px 15px',
                background: isBest
                    ? 'radial-gradient(177.15% 538.49% at 50% 50%, #F5F5F5 0%, #FFCD00 100%)'
                    : isSecond
                        ? 'radial-gradient(177.15% 538.49% at 50% 50%, #F5F5F5 0%, #E8E6E6 100%)'
                        : 'background.paper',
                margin: '10px 20px 0px 20px',
                cursor: blurred ? 'default' : 'pointer',
                position: 'relative',
                '&:hover': {
                    boxShadow: blurred ? 0 : 6,
                    transition: 'box-shadow 0.3s ease-in-out'
                }
            }}
            onClick={handleClick}
        >
            <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Typography
                    sx={{
                        fontSize: '17px',
                        fontWeight: '600',
                        height: '42px',
                        lineHeight: '21px',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {company.name}
                </Typography>
            </Box>

            <Box
                sx={{
                    color: 'common.white',
                    padding: '4px 20px',
                    borderRadius: 1,
                    textAlign: 'center',
                    width: '100%',
                    marginBottom: 3
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <STIScore score={company.stiScore} />
            </Box>

            <Box sx={{ width: '100%', mb: 2 }}>
                <BasicTable
                    data={{
                        'Symbol': company.symbol,
                        'Market Cap': company.marketCap,
                        'Industry': company.industry,
                        'Sector': company.sector
                    }}
                    isLoading={false}
                />
            </Box>

            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#FF9800',
                    color: 'common.white',
                    '&:hover': {
                        backgroundColor: '#F57C00',
                    },
                    padding: '5px 32px',
                    marginTop: 2
                }}
                onClick={blurred ? onReveal : handleClick}
            >
                {blurred ? 'SHOW' : 'CHECK'}
            </Button>

            {blurred && (
                <Backdrop
                    sx={{
                        position: 'absolute',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.85)',
                        backdropFilter: 'blur(6px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                        borderRadius: 1,
                        paddingTop: '40px'
                    }}
                    open={true}
                >
                    {/* Create space for the crown icon even when it's not shown to keep buttons aligned */}
                    {isBest ? (
                        <Box
                            component="img"
                            src={crownIcon}
                            alt="Crown icon"
                            sx={{
                                width: 32,
                                height: 32,
                                mb: 1,
                                filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.2))'
                            }}
                        />
                    ) : (
                        <Box sx={{ width: 32, height: 32, mb: 1 }} /> // Empty box with same dimensions
                    )}
                    <Typography sx={{
                        mb: 2,
                        textAlign: 'center',
                        px: 5,
                        fontSize: '15px',
                        fontWeight: 600,
                        height: '40px',  // Fixed height for both one-line and two-line text
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {isBest ? 'TOP STI Score' : 'Company with Higher STI Score'}
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#9C27B0',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#7B1FA2',
                            },
                            px: 5
                        }}
                        onClick={onReveal}
                    >
                        CHECK
                    </Button>
                </Backdrop>
            )}
        </Card>
    );
};

interface SimilarCompaniesBlurredProps {
    stockSymbol: string;
    paperHeight?: string;
}

const SimilarCompaniesBlurred = ({ stockSymbol, paperHeight = '480px' }: SimilarCompaniesBlurredProps) => {
    interface SimilarCompaniesData {
        similar: Company;
        secondPlace: Company;
        best: Company;
    }

    const { data: similarCompanies, isLoading } = useSimilarCompaniesBasedOnScore(stockSymbol) as {
        data: SimilarCompaniesData | undefined;
        isLoading: boolean;
    };
    const globalClasses = useGlobalStyles();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const { data: subscriptionLevel } = useSubscriptionLevel();
    const freeResourcesExceededDialog = useFreeResourcesExceededDialogContext();

    const [revealedCards, setRevealedCards] = useState({
        similar: true,
        secondPlace: false,
        best: false
    });

    const handleReveal = async (cardType: 'similar' | 'secondPlace' | 'best') => {
        if (!isAuthenticated) {
            // Redirect to login instead of showing dialog
            instance.loginRedirect(loginRequest);
            return;
        }

        // You would implement your premium feature check logic here
        // For example:
        let canReveal = false;
        const isPremium = subscriptionLevel && SubscriptionService.isPremium(subscriptionLevel as number);

        if ( isPremium ) {
            canReveal = true;
        }

        try {
            if(!canReveal && cardType == 'secondPlace'){
                const canSee = await SubscriptionService.CanSeeFunctionality(FunctionalityType.SimilarCompanyBasedOnStiScore);
                if (canSee) {
                    canReveal = true;
                }
            }
        } catch (error: any) { // Type the error as 'any' to access properties
            if (error && error.response && error.response.status === 403) {
                freeResourcesExceededDialog.setDialogText('Top companies based on STI score');
                freeResourcesExceededDialog.setIsDialogOpened(true);
            } else {
                // Handle any other errors
                console.error("Error checking functionality access:", error);
            }
        }

        if (!canReveal) {
            freeResourcesExceededDialog.setIsDialogOpened(true);
            freeResourcesExceededDialog.setDialogText("Top companies based on STI score");
            return;
        }

        setRevealedCards(prev => ({
            ...prev,
            [cardType]: true
        }));
    };

    return (
        <Paper
            sx={{
                width: '100%',
                height: { xs: '100%', lg: paperHeight},
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}
            elevation={4}
        >
            <Typography
                sx={{
                    padding: '15px 50px 5px 15px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '16px',
                    fontWeight: 700
                }}
                variant="h6"
                className={globalClasses.header}
            >
                SIMILAR COMPANIES STI SCORE
            </Typography>
            {isLoading || !similarCompanies ? (
                <Box sx={{ width: '100%', minHeight: '150px', flexGrow: 1 }} className="center-items">
                    <Loader />
                </Box>
            ) : (
                <Grid container sx={{ paddingTop: '20px', paddingBottom: '20px' }} spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                            Similar STI Score
                        </Typography>
                        <SimilarCompaniesCard
                            isBest={false}
                            isSecond={false}
                            company={similarCompanies?.similar}
                            blurred={!revealedCards.similar}
                            onReveal={() => handleReveal('similar')}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                            2nd position
                        </Typography>
                        <SimilarCompaniesCard
                            isBest={false}
                            isSecond={true}
                            company={similarCompanies?.secondPlace}
                            blurred={!revealedCards.secondPlace}
                            onReveal={() => handleReveal('secondPlace')}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                            The best
                        </Typography>
                        <SimilarCompaniesCard
                            isBest={true}
                            isSecond={false}
                            company={similarCompanies?.best}
                            blurred={!revealedCards.best}
                            onReveal={() => handleReveal('best')}
                        />
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
};

export default SimilarCompaniesBlurred;