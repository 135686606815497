import {
    Box,
    Button,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DocumentsMenu from './DocumentsMenu';

function Footer() {
    const navigate = useNavigate();

    return (
        <Box
            className="footer"
            sx={{
                width: '100%',
                height: '35px',
                background: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Stack direction="row" spacing={2}>
                <IconButton
                    size="large"
                    aria-label="pricing"
                    onClick={() => { navigate('pricing') }}
                    color="inherit"
                    style={{ float: 'left', borderRadius: '0px' }}
                >
                    <Typography sx={{ overflow: 'hidden', fontWeight: '600' }} variant="body2">
                        Pricing
                    </Typography>
                </IconButton>
                <DocumentsMenu />
            </Stack>
        </Box>
    );
}

export default Footer;