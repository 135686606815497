import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  Button, Popover, Typography, Box, Paper,
} from '@mui/material';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import ROUTES from '../constants/routes';
import StockAdvancedKeyTakeaways from './KeyTakeaways/StockAdvancedKeyTakeaways/StockAdvancedKeyTakeaways';
import useSubscriptionLevel from '../hooks/useSubscriptionLevel';
import SubscriptionService from '../services/subscriptionService';
import { useFreeResourcesExceededDialogContext } from '../contexts/FreeResourcesExceededDialogContext';
import { loginRequest } from '../authConfig';
import { FunctionalityType } from '../models/subscriptionConfig';

interface StiScoreCellProps {
  stiScore: number | undefined | any;
  columnWidth: number | undefined;
  symbol: string;
}

export default function StiScoreCell(props: StiScoreCellProps) {
  const { stiScore, columnWidth, symbol } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [xTransform, setXTransform] = useState(0);
  const [clickedOpen, setClickedOpen] = useState(false);
  const [keyTakeawaysIsLoading, setKeyTakeawaysIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [delayHandler, setDelayHandler] = useState<any>(null);
  const { data: subscriptionLevel } = useSubscriptionLevel();
  const isAuthenticated = useIsAuthenticated();
  const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();
  const { instance } = useMsal();


  function handleMouseEnter() {
    setAnchorEl(buttonRef.current);
    setDelayHandler(
      setTimeout(() => {
        setOpen(true);
      }, 500),
    );
  }

  function canSeeScore() {
    if (subscriptionLevel && SubscriptionService.isPremium(subscriptionLevel as number)) {
      return true;
    }

    if (clickedOpen) {
      return true;
    }

    return false;
  }

  function handleMouseLeave() {
    clearTimeout(delayHandler);
    setOpen(false);
  }

  function handleStockButtonClick() {
    window.open(ROUTES.stockDetailedData(symbol), '_blank');
    window.scrollTo(0, 0);
  }

  function handleShowStiScoreButtonClick() {
    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }
    else {
      SubscriptionService.CanSeeFunctionality(FunctionalityType.StiScore)
        .then(canSee => {
          if (canSee) {
            setClickedOpen(true);
          }
        })
        .catch(error => {
          if (error?.response?.status === 403) {
            freeResourcesExceededDialogContext.setDialogText('STI Score');
            freeResourcesExceededDialogContext.setIsDialogOpened(true);
          }
        });
    }
  }

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setAnchorEl(buttonRef.current);
  }, []);

  useEffect(() => {
    if (!buttonRef.current?.clientWidth) return;
    if (!columnWidth) {
      setXTransform(buttonRef.current?.clientWidth);
    } else if (columnWidth - 10 > buttonRef.current.clientWidth) {
      setXTransform(buttonRef.current.clientWidth);
    } else {
      setXTransform(columnWidth - 10);
    }
  }, [columnWidth]);

  const cellButton = useMemo(
    () => (
      <Button
        sx={{
          width: '100%',
          height: '100%',
          textAlign: 'left',
          alignItems: 'center',
        }}
        variant="text"
        ref={buttonRef}
        onClick={() => handleStockButtonClick()}
      >
        <Typography sx={{ overflow: 'hidden' }} variant="inherit">
          {stiScore}
        </Typography>
      </Button>
    ),
    [],
  );

  if (!symbol) {
    return null;
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {canSeeScore() ?
        (<Box sx={{ overflow: 'hidden' }}>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {cellButton}
            <div>
              <Popover
                key={JSON.stringify([name, keyTakeawaysIsLoading])}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{
                  transform: `translateX(${xTransform}px)`,
                  margin: 0,
                  padding: 0,
                  pointerEvents: 'none',
                }}
                disableEnforceFocus
              >
                <Paper
                  sx={{
                    width: '800px',
                    height: 'fit-content',
                    margin: 0,
                    padding: 0,
                    pointerEvents: 'auto',
                  }}
                >
                  <StockAdvancedKeyTakeaways
                    symbol={symbol}
                    paperHeight='400px'
                  />
                </Paper>
              </Popover>
            </div>
          </div>
        </Box>)
        :
        (<Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          border: '1px solid transparent' // Added to maintain box dimensions
        }}>
          <Button
            sx={{
              padding: '2px 8px',
              background: 'rgba(33, 150, 243, 1)',
              borderRadius: '4px',
              minWidth: '51px',
              minHeight: '21px',
              boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                0px 1px 5px 0px rgba(0, 0, 0, 0.12)`
            }}
            variant="contained"
            onClick={handleShowStiScoreButtonClick}
          >
            <Typography sx={{ fontSize: '8px' }} variant="inherit">
              SHOW
            </Typography>
          </Button>
        </Box>)
      }
    </Box>
  );
}
